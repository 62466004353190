<script>
import Vue from 'vue'
import VueGtm from 'vue-gtm'
import axios from 'axios'
import { FormGenerator } from 'aca-form-generator'

import Handlebars from '@/Handlebars.js'
import { getCookieValue, transformSteps, getPayload, timeWithOffset, findInDataLayer } from '@/helpers'
import {
    dataForProspectsNew,
    dataForCreateTask,
    dataForTeachersNew,
    dataForWebCallback,
    dataForEmail,
    dataForNewsletter,
    dataForCreatePartner,
} from '@/postProcesses.js'

import { BaseButton, IconArrowLeft, IconArrowRight } from 'aca-design'

import BaseSpinner from '@/components/BaseSpinner'
import InstacoursDateTime from '@/components/InstacoursDateTime'


const arrayToObject = (array, keyField = 'id') => {
    return array.reduce((obj, item) => {
        obj[item[keyField]] = item
        return obj
    }, {})
}

const isObject = (value) => {
    if (
        typeof value === 'object' &&
        !Array.isArray(value) &&
        value !== null
    ) {
        return true
    }
    return false
}

const useDBExtract = true
const autoContinuableFields = ['radio', 'select']

export default {
    name: 'FormScreen',

    components: {
        FormGenerator,
        BaseButton,
        BaseSpinner,
        IconArrowRight,
        IconArrowLeft,
        InstacoursDateTime,
    },

    props: {
        landingName: String,
        defaultPrefilledModel: {
            type: String,
        },
    },

    data() {
        return {
            errors: {},
            visibleErrors: {},
            prefilledModel: {},
            model: {},
            apiSteps: undefined,
            stepsBehind: [],
            rgpd: undefined,
            postLoading: false,
            landing: undefined,
            blockGroups: undefined,
            success: false,
            transitioning: false,
            settings: {},
            history: [],
            currentStepIndex: 0,
            scoreDefinitionFunction: undefined,
            scoreMessages: []
        }
    },

    computed: {
        postProcess() {
            if (this.continueTo.postProcess) {
                return this.continueTo.postProcess
            }
            return this.landing?.post_process
        },

        uniqSteps() {
            // removes steps sharing a common model key
            const filterHtmlFields = function (field) {
                return field.type !== 'html'
            }
            const getStepModelKeys = function (fields) {
                return fields.map((field) => field.model)
            }

            return Object.values(this.steps).filter((step, index, self) => {
                const fields = step.schema.fields.filter(filterHtmlFields)
                const modelKeys = getStepModelKeys(fields)

                return (
                    index ===
                    self.findIndex((stepB) => {
                        const fieldsB = stepB.schema.fields.filter(filterHtmlFields)
                        const modelKeysB = getStepModelKeys(fieldsB)

                        const intersection = modelKeys.filter((modelKey) => modelKeysB.includes(modelKey))
                        return intersection.length
                    })
                )
            })
        },

        trackingPayload() {
            return getPayload(this.model)
        },

        processedPayload() {
            const model = this.mergeLoopedOverModel(
                this.model, this.steps
            )

            if (this.postProcess === 'create_tasks') {
                return dataForCreateTask(model, this.name, 'family')
            } else if (this.postProcess === 'create_tasks_contact') {
                return dataForCreateTask(model, this.name, 'family')
            } else if (this.postProcess === 'webcallback') {
                return dataForWebCallback(model)
            } else if (this.postProcess === 'teachers') {
                return dataForTeachersNew(model)
            } else if (this.postProcess === 'prospects') {
                return dataForProspectsNew(model)
            } else if (this.postProcess === 'create_partner') {
                return dataForCreatePartner(model)
            } else if (this.postProcess === 'newsletter') {
                return dataForNewsletter(model)
            }


            return model
        },

        currentStepSlug() {
            return this.history[this.history.length - 1]
        },

        currentStep() {
            return this.steps[this.currentStepSlug]
        },

        autoContinue() {
            return this.canAutoContinueStep(this.currentStep, this.model)
        },

        continueTo() {
            if (!this.currentStep) {
                return undefined
            }
            const continueTo = this.currentStep.continueTo(this.model, this.currentStep.schema.fields)
            if (isObject(continueTo)) {
                return continueTo
            } else {
                return {
                    step: continueTo
                }
            }
        },

        continueToStep() {
            if (!this.continueTo) {
                return undefined
            }
            return this.continueTo.step
        },

        hasError() {
            return Object.values(this.errors).length > 0
        },

        canSubmit() {
            return !this.hasError
        },

        canContinue() {
            if (this.transitioning) {
                return false
            }
            return this.continueToStep !== undefined && !this.hasError
        },

        canGoBack() {
            return Object.keys(this.steps)[0] !== this.currentStepSlug
        },

        steps() {
            if (!this.apiSteps?.length) {
                return []
            }

            let steps = Object.assign({},
                transformSteps(this.apiSteps, { goToStep: this.goToStep})
            )

            Object.keys(steps).forEach((key) => {
                steps[key].schema.fields = this.expandLoopedOverFields(
                    steps[key].schema.fields,
                    this.model,
                )
            })

            return this.withoutHiddenChoices(steps, this.model)
        },

        score() {
            if(!this.scoreDefinitionFunction) {
                return 0
            }

            let score = 0
            const definition = this.scoreDefinitionFunction(this.model)

            Object.keys(this.model).forEach((key) => {
                const value = this.model[key]
                if (definition[key] && definition[key][value]) {
                    score = score + definition[key][value]
                }
            })
            return score
        },

        scoreMessage() {
            let scoreMessage

            this.scoreMessages.forEach(({ min, max, message }) => {
                if (this.score >= min && this.score < max) {
                    scoreMessage = message
                }
            })

            return Handlebars.compile(scoreMessage || '')({ score: this.score })
        },
    },

    watch: {
        currentStepSlug: {
            handler: function (currentStepSlug) {
                this.$emit('message', {
                    eventName: 'ACA_FORM_STEP_CHANGED',
                    payload: {
                        currentStepSlug: this.currentStepSlug,
                    },
                })

                if (currentStepSlug && Object.keys(this.steps || {}).length) {
                    const stepSlug = this.currentStepSlug
                    const index = this.stepsBehind.indexOf(stepSlug)
                    if (index >= 0) {
                        const passedAndCurrentSteps = this.stepsBehind.slice(index)
                        this.stepsBehind.splice(index + 1)

                        passedAndCurrentSteps.forEach((slug) => {
                            const step = this.steps[slug]
                            if (this.canAutoContinueStep(step, this.model)) {
                                step.schema.fields.forEach(({ model }) => {
                                    if (this.prefilledModel[model] === undefined) {
                                        delete this.model[model]
                                        delete this.visibleErrors[model]
                                    }
                                })
                            }
                        })
                    } else {
                        this.stepsBehind.push(stepSlug)
                    }
                }
            },

            immediate: true,
        },

        model: {
            handler: function () {
                this.$emit('message', {
                    eventName: 'ACA_FORM_MODEL_CHANGED',
                    payload: {
                        model: this.model,
                    },
                })
            },
        },

        score: {
            handler: function () {
                this.model = {
                    ...this.model,
                    score: this.score,
                    scoreMessage: this.scoreMessage
                }
            },

            immediate: true
        },

        scoreMessage: {
            handler: function () {
                this.model = {
                    ...this.model,
                    score: this.score,
                    scoreMessage: this.scoreMessage
                }
            },

            immediate: true
        },
    },

    beforeMount() {
        this.$emit('message', { eventName: 'ACA_FORM_BEFORE_MOUNT' })
    },

    async mounted() {
        this.$emit('message', { eventName: 'ACA_FORM_MOUNTED' })

        axios.get(`${process.env.VUE_APP_API_URL}/items/global_settings`).then(({ data }) => {
            this.settings = data.data[0]
        }).catch((e) => {
            console.log('Cant retrieve global settings', e)
        })

        const landingEndpoint = `${process.env.VUE_APP_API_URL}/items/landings_iframe?filter[name][_eq]=${this.landingName}&fields=*,form.*`

        const resp = await axios.get(landingEndpoint)
        const landing = resp.data.data[0]
        this.landing = landing

        const blockIds = landing.block_groups?.map((group) => {
            return group.block_groups.block_groups
        })

        if (blockIds && blockIds.length) {
            const blockResp = await axios.get(`${process.env.VUE_APP_API_URL}/items/block_groups?filter[id][_in]=${blockIds.join(',')}`)
            this.blockGroups = arrayToObject(blockResp?.data?.data || [])
        }

        if (this.landing && this.landing.form) {
            try {
                const scoreResp =  await axios.get(`${process.env.VUE_APP_API_URL}/items/scores?filter[form][_eq]=${this.landing.form.id}&fields=*`)
                const score = scoreResp.data.data[0]
                this.scoreDefinitionFunction = new Function('model', score.score_definition)
                this.scoreMessages = score.messages
            } catch (e) { console.log(e) }
        }

        if (this.landing.gtm_id && this.landing.gtm_id.length) {
            Vue.use(VueGtm, {
                id: this.landing.gtm_id,
            })
        }

        this.prefilledModel = {
            ...this.prefilledModel,
            ...JSON.parse(this.defaultPrefilledModel || '{}'),
            ...JSON.parse(landing.prefilled_model || '{}')
        }


        this.model = { ...this.prefilledModel }
        this.model.landingName = this.landingName


        this.apiSteps = landing.form.steps

        const firstStepSlug = Object.keys(this.steps)[0]
        const nextStep = this.getNextStep(firstStepSlug)

        this.stepsBehind = [nextStep]
        this.history = [nextStep]
        this.currentStepIndex = 0

        this.$emit('message', { eventName: 'ACA_FORM_LOADED' })

        window.addEventListener('resize', () => {
            if (document.activeElement.tagName.toLowerCase() === 'textarea' || document.activeElement.tagName.toLowerCase() === 'input') {
                this.maybeScrollToElement(document.activeElement)
            }
        })
    },

    methods: {
        mergeLoopedOverModel(model, steps) {
            const mergedModel = { ...model }

            Object.keys(steps).forEach((slug) => {
                const step = steps[slug]
                const fields = step.schema.fields
                fields.forEach((field) => {
                    if (field.parentModel) {
                        if (!mergedModel[field.parentModel]) {
                            mergedModel[field.parentModel] = []
                        }
                        mergedModel[field.parentModel].push({
                            loopItem: field.loopItem,
                            value: model[field.model],
                        })
                    }
                })
            })

            return mergedModel
        },

        expandLoopedOverFields(fields, model) {
            const expandedFields = []

            fields.forEach((field) => {
                if (field.loopOver && field.loopOver(model, field)) {
                    field.loopOver(model, field).forEach((loopItem) => {
                        expandedFields.push({
                            ...field,
                            loopItem: loopItem,
                            parentModel: field.model,
                            model: `${field.model}-${loopItem}`,
                        })
                    })
                } else {
                    expandedFields.push(field)
                }
            })

            return expandedFields
        },

        withoutHiddenChoices(steps, model) {
            const newSteps = {}

            Object.keys(steps).forEach((stepKey) => {
                const step = steps[stepKey]

                newSteps[stepKey] = {
                    ...step,
                    schema:  {
                        ...step.schema,
                        fields: step.schema.fields.map((field) => {
                            if (field.choices?.length) {
                                return {
                                    ...field,
                                    choices: field.choices.filter((choice) => {
                                        return choice.visible(model)
                                    })
                                }
                            }

                            return field
                        })
                    }
                }
            })

            return newSteps
        },

        canAutoContinueStep(step, model) {
            const nonHtmlFields = step.schema.fields.filter((field) => field.type !== 'html' && field.type !== 'progress-bar' && field.type !== 'group')
            const nonHtmlVisibleFields = nonHtmlFields.filter((field) => {
                return this.isFieldVisible(field, model)
            })

            if (nonHtmlVisibleFields.every((field) => field.type === 'link' )) {
                return true
            } else if (nonHtmlVisibleFields.length === 1 && autoContinuableFields.includes(nonHtmlFields[0].type)) {
                return true
            }

            return false
        },

        trackStepGTM(data, success) {
            if (data.email === 'webmaster@acadomia.fr') {
                return console.log('Skipping GTM tracking because email is webmaster@acadomia.fr')
            }
            const payload = {
                event: 'form-step',
                stepSlug: this.currentStepSlug,
            }

            if (data.subject?.length) {
                payload.matiereLib = data.subject
            }
            if (data.level?.length) {
                payload.classLib = data.level
            }
            if (data.zip?.length) {
                payload.CP = data.zip
            }
            if (data.type?.length) {
                payload.userType = data.type
            }

            if (data.finalAgency) {
                payload.agenceId = data.finalAgency.id
            }

            if (success) {
                payload.leadConfirmType = this.getLeadConfirmType(data)
                payload.formulaireCode = data.formCode || data.formulaireCode
                payload.directusCollectionId = this.landing.id

                const successEvent = new CustomEvent("CustomEvent::FormSuccess", {
                    detail: {
                        formulaireCode: payload.formulaireCode,
                        leadConfirmType: payload.leadConfirmType,
                        directusCollectionId: payload.directusCollectionId,
                        postProcess: this.postProcess,
                        userType: payload.userType
                    },
                })

                window.dispatchEvent(successEvent)
            }

            if (success && data.email) {
                payload.usermail = data.email
            }

            console.log('Push form-step to datalayer', this.currentStepSlug, payload)

            window.dataLayer.push(payload)
        },

        getLeadConfirmType(data) {
            if (data.leadConfirmType) {
                return data.leadConfirmType
            } else if (data.typeForm === 'CDOM') {
                return 'prospect-soutien-scolaire'
            } else if (data.typeForm === 'CLIVE') {
                return 'prospect-soutien-scolaire'
            } else if (data.typeForm === 'CSTAGE') {
                return 'prospect-soutien-scolaire'
            } else if (data.typeForm === 'CMUS') {
                return 'prospect-musique'
            } else {
                return 'prospect-soutien-scolaire'
            }
        },

        getStepWithModel(steps, model) {
            return Object.keys(steps).find((stepKey) => {
                return steps[stepKey].schema.fields.some((field) => {
                    return field.model === model
                })
            })
        },

        isFieldVisible(field, model) {
            if (field.visible === undefined) {
                return true
            } else if (typeof field.visible === 'function') {
                return !(field.visible(model) === false)
            } else {
                return field.visible
            }
        },

        shouldSkipStep(step, model, prefilledModel) {
            const nonHtmlFields = step.schema.fields.filter((field) => field.type !== 'html' && field.type !== 'progress-bar')
            const nonHtmlVisibleFields = nonHtmlFields.filter((field) => {
                return this.isFieldVisible(field, model)
            })

            const nonAutoContinuableFields = nonHtmlVisibleFields.filter((field) => {
                return !autoContinuableFields.includes(field.type)
            })

            if (nonAutoContinuableFields.length) {
                return false
            }

            const nonPrefilledFields = nonHtmlVisibleFields.filter((field) => {
                return prefilledModel[field.model] === undefined
            })

            return !nonPrefilledFields.length
        },

        displayStepErrors() {
            const visibleErrors = Object.keys(this.errors).reduce((accumulator = {}, value) => {
                accumulator[value] = true
                return accumulator
            }, undefined)
            this.visibleErrors = visibleErrors

            this.$nextTick(() => {
                this.$el.querySelector('.form-generator-field .errors')?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                })
            })
        },

        getNextStep(startingFromStep) {
            let shouldSkipStep
            let nextStep = startingFromStep

            do {
                shouldSkipStep = this.shouldSkipStep(this.steps[nextStep], this.model, this.prefilledModel)
                if (shouldSkipStep) {
                    const continueTo = this.steps[nextStep].continueTo(this.model, this.steps[nextStep].schema.fields)

                    if (isObject(continueTo)) {
                        nextStep = continueTo.step
                    } else {
                        nextStep = continueTo
                    }
                    console.log(`skip to : `, nextStep)
                }
            } while (shouldSkipStep)

            return nextStep
        },

        goToStep(step, options = {}) {
            if (options.validate === true) {
                if (this.hasError) {
                    this.displayStepErrors()
                    return
                }
            }

            this.history.push(step)
        },

        getContactPeriod() {
            const franceDate = timeWithOffset(2)
            const hour = franceDate.getHours()
            const day = franceDate.getDay()

            // from monday to thursday
            if ([1, 2, 3, 4].includes(day) && hour > 18) {
                return 'demain matin'
            }
            // friday
            if ([5].includes(day) && hour > 18) {
                return 'dès lundi'
            }
            // saturday and sunday
            if ([6, 0].includes(day) && hour > 18) {
                return 'dès lundi'
            }

            return 'très rapidement'
        },

        handlePrevClick() {
            this.history.pop()
        },

        handleNextClick() {
            if (!this.canContinue) {
                this.displayStepErrors()
                return
            }
            if (this.continueToStep === -1) {
                return console.error('Call handleSubmitClick instead')
            }

            try {
                this.trackStepGTM(this.trackingPayload, false)
            } catch (e) {
                console.log('Error while sending analytics (maybe ad-blocked)')
            }

            // disable during transition
            // to avoid double click skipping steps
            this.transitioning = true
            setTimeout(() => {
                this.transitioning = false
            }, 400)

            this.history.push(this.getNextStep(this.continueToStep))
        },

        handleProgressBarStepClick(step) {
            const foundStep = this.stepsBehind.find((stepSlug) => {
                return this.steps[stepSlug].schema.fields.find((field) => {
                    return parseInt(field.currentStep) === parseInt(step)
                })
            })

            if (foundStep) {
                this.history.push(foundStep)
            }
        },

        async handleSubmitClick() {
            if (!this.canSubmit) {
                this.displayStepErrors()
                return
            }
            if (this.postLoading) {
                return
            }

            this.model = {
                ...this.model,
                gtm_gclid: getCookieValue('gtm_gclid') || findInDataLayer('gclid'),
                gtm_ua_client_id: getCookieValue('gtm_ua_client_id') || findInDataLayer('uaClientId'),
                gtm_msclkid: getCookieValue('gtm_msclkid') || findInDataLayer('msclkid'),
                utmSource: getCookieValue('utmSource') || findInDataLayer('utmSource'),
                utmMedium: getCookieValue('utmMedium') || findInDataLayer('utmMedium'),
                utmCampaign: getCookieValue('utmCampaign') || findInDataLayer('utmCampaign'),
                utmContent: getCookieValue('utmContent') || findInDataLayer('utmContent'),
            }

            let data
            let endpoint
            let headers = {}

            if (this.postProcess === 'prospects') {
                endpoint = `${process.env.VUE_APP_CONTACT_API_URL}/demande-documentation-client`
                data = dataForProspectsNew(
                    this.mergeLoopedOverModel(
                        this.model, this.steps
                    )
                )
            } else if (this.postProcess === 'newsletter') {
                endpoint = `${process.env.VUE_APP_CONTACT_API_URL}/newsletter`
                data = dataForNewsletter(this.model)
            } else if (this.postProcess === 'create_tasks_email') {
                endpoint = `${process.env.VUE_APP_CONTACT_API_URL}/tasks_and_email`
                data = dataForCreateTask(
                    this.mergeLoopedOverModel(
                        this.model, this.steps
                    ), '', 'family'
                )
            } else if (this.postProcess === 'webcallback') {
                endpoint = `${process.env.VUE_APP_CONTACT_API_URL}/agence/meRappeler`
                data = dataForWebCallback(
                    this.mergeLoopedOverModel(
                        this.model, this.steps
                    )
                )
            } else if (this.postProcess === 'teachers') {
                endpoint = `${process.env.VUE_APP_CONTACT_API_URL}/demande-candidature`
                if (this.model.cv?.[0]) {
                    const { base64, file } = this.model.cv[0]

                    try {
                        this.postLoading = true

                        const response  = await axios.post(`${process.env.VUE_APP_CONTACT_API_URL}/upload-cv`, {
                            file: base64,
                            fileName: file.name
                        }, {
                            headers: {
                                'Content-Type': 'application/json'
                            }
                        })

                        this.model.urlCV = response.data.nomFichier
                    } catch (error) {
                        const stepSlug = this.getStepWithModel(this.steps, 'cv')
                        const stepIndex = this.history.indexOf(stepSlug)

                        this.history = this.history.slice(0, stepIndex + 1)
                        this.$nextTick(() => {
                            this.errors = {
                                ...this.errors,
                                cv: ['Ce champ est invalide.']
                            }

                            this.visibleErrors = {
                                ...this.visibleErrors,
                                cv: true
                            }
                        })

                        return
                    }
                }

                data = dataForTeachersNew(
                    this.mergeLoopedOverModel(
                        this.model, this.steps
                    )
                )
            } else if (this.postProcess === 'create_tasks') {
                endpoint = `${process.env.VUE_APP_CONTACT_API_URL}/tasks`
                data = dataForCreateTask(
                    this.mergeLoopedOverModel(
                        this.model, this.steps
                    ),
                    this.name,
                    'family'
                )
            } else if (this.postProcess === 'create_tasks_contact') {
                endpoint = `${process.env.VUE_APP_CONTACT_API_URL}/tasks_and_contact`
                data = dataForCreateTask(
                    this.mergeLoopedOverModel(
                        this.model, this.steps
                    ),
                    this.name,
                    'family'
                )
            } else if (this.postProcess === 'create_partner') {
                endpoint = `${process.env.VUE_APP_CONTACT_API_URL}/partenariat/creerDossier`
                data = dataForCreatePartner(
                    this.mergeLoopedOverModel(
                        this.model, this.steps
                    )
                )
            } else if (this.postProcess === 'email') {
                endpoint = `${process.env.VUE_APP_CONTACT_API_URL}/mail`
                data = {
                    payload: btoa(encodeURIComponent(JSON.stringify(dataForEmail(
                        this.mergeLoopedOverModel(
                            this.model, this.steps
                        )
                    ))))
                }
            }

            if (this.settings?.post_process_function) {
                const func = new Function('model', 'payload', 'postProcess',  this.settings.post_process_function)
                const funcData = func(this.model, data, this.postProcess)
                data = {
                    ...data,
                    ...funcData
                }
                // for success messages and gtm tracking
                this.model = {
                    ...this.model,
                    ...funcData
                }
            }
            let postProcessResponse

            try {
                if (
                    (this.continueTo.sendData === true) ||
                    (this.continueTo.sendData === undefined && this.landing.send_data)
                ) {
                    let agency
                    this.postLoading = true
                    postProcessResponse = await axios.post(endpoint, data, { headers })

                    if (this.postProcess === 'prospects') {
                        const agencyId = Array.isArray(data.agenceId) ? data.agenceId[0] : data.agenceId
                        if (agencyId) {
                            const agencyResponse = await axios.get(`${process.env.VUE_APP_CONTACT_API_URL}/agence/chargerAgenceParId?agenceId=${agencyId}`)
                            agency = agencyResponse.data
                        } else if (data.codePostal) {
                            const agencyResponse = await axios.get(`${process.env.VUE_APP_CONTACT_API_URL}/agence/chargerAgenceParCodePostal?codePostal=${data.codePostal}`, {
                                codePostal: data.codePostal
                            }, { headers })
                            agency = agencyResponse.data
                        }
                    }

                    this.model = Object.assign({}, this.model, {
                        finalAgency: agency,
                        finalAgencyId: agency?.id,
                        contactPeriod: this.getContactPeriod(),
                        response: postProcessResponse.data
                    })
                }

                this.postLoading = false
                this.success = true

                this.$emit('message', {
                    eventName: 'ACA_FORM_POST_SUCCESS',
                })

                try {
                    this.trackStepGTM(this.trackingPayload, true)
                } catch (e) {
                    console.log('Error while sending analytics (maybe ad-blocked)')
                }
                this.$nextTick(() => {
                    document.querySelector('.success-wrapper')?.scrollIntoView({
                        behavior: 'smooth'
                    })
                })
            } catch (e) {
                console.error(e)
                this.postLoading = false
                this.$emit('message', {
                    eventName: 'ACA_FORM_POST_FAILURE',
                })

                if (
                    this.postProcess === 'create_partner' &&
                    e &&
                    e.response &&
                    e.response.data &&
                    e.response.data.details &&
                    e.response.data.details.includes(`Il n'y a pas une correspondance dans la table partenariat_club avec partgrandcompteId reçu et partcubIdentifiant reçu`)
                ) {
                    const stepSlug = this.getStepWithModel(this.steps, 'partclubIdentifiant')
                    const stepIndex = this.history.indexOf(stepSlug)

                    this.history = this.history.slice(0, stepIndex + 1)
                    this.$nextTick(() => {
                        this.errors = {
                            ...this.errors,
                            partclubIdentifiant: ['Ce champ est invalide.']
                        }

                        this.visibleErrors = {
                            ...this.visibleErrors,
                            partclubIdentifiant: true
                        }
                    })
                }
            }

            if (
                (this.continueTo.extractData === true) ||
                (this.continueTo.extractData === undefined && this.landing.extract_data)
            ) {
                if (useDBExtract) {
                    axios.post(`${process.env.VUE_APP_CONTACT_API_URL}/extracts`, {
                        payload: data,
                        model: this.model,
                        formId: this.landing.form.id,
                        formName: this.landing.form.name,
                        postProcess: this.landing.post_process,
                        collection: 'landings_iframe',
                        collectionItemId: this.landing.id,
                        collectionItemName: this.landing.name,
                        apiResponseCode: postProcessResponse.status,
                    }, {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }).catch((e) => {
                        console.error('Error posting customer')
                        console.error(e)
                    })
                } else {
                    axios.post(`${process.env.VUE_APP_CONTACT_API_URL}/sheets/row`, {
                        title: this.landing.name,
                        row: data
                    }, {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    })
                }
            }
        },

        handleFormSubmit() {
            if (this.continueToStep === -1) {
                return this.handleSubmitClick()
            } else {
                return this.handleNextClick()
            }
        },

        handleErrorChange({ errors }) {
            this.errors = errors
        },

        handleFieldChange({ field, value }) {
            if (field.type === 'progress-bar') {
                return this.handleProgressBarStepClick(value)
            }

            if (field.model === 'zipcode' && value && value.cpCode) {
                this.getAgencyByZipcode(value.cpCode).then((agencyData) => {
                    this.model = {
                        ...this.model,
                        finalAgency: {
                            ...agencyData,
                            formattedPhone: agencyData.agenceTelephone.replace(/(.{2})/g,"$1 ")
                        }
                    }
                })
            }

            this.model = Object.assign({}, this.model, {
                [field.model]: value,
            })

            if (!['input', 'numeric-input', 'date-input', 'textarea', 'google-address', 'phone-number'].includes(field.type)) {
                this.visibleErrors = Object.assign({}, this.visibleErrors, {
                    [field.model]: true,
                })
            }

            // empty not prefilled invisible fields
            this.currentStep.schema.fields.forEach((field) => {
                const isVisible = this.isFieldVisible(field, this.model)
                const isPrefilled = this.model[field.model] !== undefined
                if (!isVisible && !isPrefilled) {
                    this.model = Object.assign({}, this.model, {
                        [field.model]: undefined,
                    })
                }
            })

            if (autoContinuableFields.includes(field.type) && this.autoContinue && this.continueToStep !== -1) {
                // So we see the ui with the selected field before changing step
                setTimeout(() => {
                    this.handleNextClick()
                }, 200)
            }
        },

        handleFieldBlur({ field }) {
            const value = this.model[field.model]
            if (!value) {
                return
            }
            if (!(value instanceof Object) && !value.length) {
                return
            }
            this.visibleErrors = Object.assign({}, this.visibleErrors, {
                [field.model]: true,
            })
        },

        handleFieldFocus({ $event }) {
            this.maybeScrollToElement($event.target)
        },

        // handleButtonsClick() {
        //     const visibleErrors = Object.keys(this.errors).reduce((accumulator = {}, value) => {
        //         accumulator[value] = true
        //         return accumulator
        //     }, undefined)
        //     this.visibleErrors = visibleErrors
        // },

        // fix for fixed footer overlapping inputs
        // when chrome mobile keyboard opens
        maybeScrollToElement(element) {
            const scrollTop = window.pageYOffset || (document.documentElement || document.body.parentNode || document.body).scrollTop
            const domRect = element.getBoundingClientRect()
            const spaceBelow = window.innerHeight - domRect.bottom

            if (spaceBelow <= 100) {
                window.scrollTo(0, scrollTop + 100 - spaceBelow)
            }
        },

        async getAgencyByZipcode(zipcode) {
            const agencyResponse = await axios.get(`${process.env.VUE_APP_CONTACT_API_URL}/agence/chargerAgenceParCodePostal?codePostal=${zipcode}`, {
                codePostal: zipcode
            })

            return agencyResponse.data
        },

        isVisible(blockGroup, model) {
            if (!blockGroup.visible) {
                return true
            }

            const isVisibleFunction = new Function('model', blockGroup.visible)
            return isVisibleFunction(model)
        },

        mustacheString(string, model, payload) {
            return Handlebars.compile(string || '')({ model, payload })
        },

        mustacheBlock(block, model, payload) {
            return {
                ...block,
                wysiwyg: Handlebars.compile(block.wysiwyg || '')({ model, payload }),
                title: Handlebars.compile(block.title || '')({ model, payload }),
                wysiwyg_repeater: (block.wysiwyg_repeater || []).map(({ wysiwyg }) => {
                    return {
                        wysiwyg: Handlebars.compile(wysiwyg || '')({ model, payload })
                    }
                })
            }
        },

        mustacheSchema(schema, model, payload) {
            return {
                ...schema,
                fields: schema.fields.map((field) => {
                    if (field.type === 'html') {
                        return {
                            ...field,
                            value: Handlebars.compile(field.value || '')({ model, payload }),
                        }
                    }

                    if (field.type === 'link') {
                        return {
                            ...field,
                            value: Handlebars.compile(field.value || '')({ model, payload })
                        }
                    }

                    if (field.choices?.length) {
                        return {
                            ...field,
                            choices: field.choices.map((choice) => {
                                return {
                                    ...choice,
                                    name: Handlebars.compile(choice.name || '')({ model, payload })
                                }
                            })
                        }
                    }

                    if (field.label) {
                        return {
                            ...field,
                            label: Handlebars.compile(field.label || '')({
                                model: model,
                                payload: payload,
                                loopItem: field.loopItem
                            })
                        }
                    }
                    return field
                }),
            }
        },
    },
}
</script>
<template>
    <div v-if="steps && currentStep" class="form-screen" :class="[`step-${currentStepSlug}`, `landing-${landing.name}`, `${landing.classname}`]">
        <template v-if="success">
            <div class="success-wrapper">
                <template v-for="blockGroup in landing.block_groups">
                    <div v-if="isVisible(blockGroup, model)"
                      :key="blockGroup.block_groups.block_groups"
                      class="block-group"
                      :class="blockGroup.classname">
                        <div class="block-type-wysiwyg"
                          v-for="block in blockGroups[blockGroup.block_groups.block_groups].blocks"
                          v-html="mustacheBlock(block, model, processedPayload).wysiwyg"
                          :class="block.classname"
                          :key="block.id">
                            {{ block }}
                        </div>
                    </div>
                </template>
            </div>
        </template>
        <template v-else>
            <div class="steps-wrapper">
                <div :class="`${currentStep.classname} step-item`" :key="currentStepSlug">
                    <FormGenerator
                        :schema="mustacheSchema(currentStep.schema, model, processedPayload)"
                        :model="model"
                        :errors="errors"
                        :visibleErrors="visibleErrors"
                        @errorsChange="handleErrorChange"
                        @fieldChange="handleFieldChange"
                        @fieldBlur="handleFieldBlur"
                        @fieldFocus="handleFieldFocus"
                    >
                        <template #instacours-date-time="{ field, value }">
                            <InstacoursDateTime
                              :field="field"
                              :value="model[field.model]"
                              :class="field.classname"
                              @change="($event) => handleFieldChange({field, value: $event})"
                              @focus="($event) => $emit('fieldFocus', { $event, field })"
                              @blur="($event) => $emit('fieldBlur', { $event, field })">
                                <span slot="label" v-html="field.label"></span>
                            </InstacoursDateTime>
                            <div class="hint-text" v-if="field.hint && field.hint.length" v-html="field.hint"></div>
                        </template>

                        <template #after>
                            <div class="button-wrapper" v-if="canGoBack || Object.keys(steps).length === 1 || canSubmit || canContinue">
                                <BaseButton class="base-button-outline back-button" :disabled="!canGoBack" @click.native="handlePrevClick">
                                    <IconArrowLeft style="margin-right: 10px" />
                                    <span> Précédent </span>
                                </BaseButton>
                                <BaseButton v-if="continueToStep === -1" class="base-button-solid" @click.native="handleSubmitClick" :disabled="!canSubmit">
                                    <template v-if="postLoading">
                                        <span>Ne quittez pas</span>
                                        <BaseSpinner style="margin-left: 12px; width: 22px" />
                                    </template>
                                    <template v-else>
                                        {{ landing.submit_button_label || 'Valider' }}
                                    </template>
                                </BaseButton>
                                <BaseButton class="base-button-solid" v-else :disabled="!canContinue" :style="autoContinue ? { opacity: 0 } : {}" @click.native="handleNextClick">
                                    <span>Suivant</span>
                                    <IconArrowRight style="margin-left: 10px" />
                                </BaseButton>
                            </div>
                        </template>
                    </FormGenerator>
                </div>
            </div>


            <div class="cgu-wrapper" v-if="landing && landing.rgpd && landing.rgpd.length && continueToStep === -1">
                <div class="cgu" v-html="landing.rgpd"></div>
            </div>
        </template>
    </div>
</template>
<style lang="stylus">
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600&display=swap')
@import '~aca-design/dist/aca-design.css'
@import '~balloon-css/balloon.min.css'
@import '../assets/css/utilities.styl'

.form-screen
    max-width 100vw
    // overflow-x hidden
    background transparent

    .base-button.disabled
        pointer-events auto

        &.base-button-solid.not-touch-device:hover
            background var(--AkGreyscaleMediumLtGrey)
            border-color var(--AkGreyscaleMediumLtGrey)
            color var(--AkGreyscaleDarkGrey)

    & > * + *
        margin-top 30px

    .question-mark
        --balloon-color: var(--color1)
        --balloon-text-color: var(--color1Inverse)
        display inline-flex
        background #000
        color #FFF
        border-radius 50%
        width 20px
        height 20px
        align-items center
        justify-content center
        font-size 13px
        cursor pointer

    .h1, h1
        font-size 60px
        font-weight normal
        line-height 70px
        color var(--color2)
        font-family 'TorontoGothic', sans-serif
        position relative

    h2
        font-size 32px
        font-weight bold
        line-height 42px
        color var(--color2)
        position relative

    h3
        font-size 26px
        font-weight bold
        line-height 36px
        color var(--color2)

    h4
        font-size 22px
        font-weight bold
        line-height 32px
        color var(--color2)

    h5
        font-size 18px
        font-weight bold
        line-height 28px
        color var(--color2)

    h6
        font-size 16px
        font-weight bold
        line-height 26px
        color var(--color2)

    strong
        font-weight bold

    p
        font-size 18px
        font-weight normal
        font-stretch normal
        font-style normal
        line-height 1.45

    .steps-wrapper
        .base-button
            padding-left 1rem
            padding-right 1rem

    @media screen and (min-width: 1025px)
        .form-generator-field
            &{unquote(".lg\:w-1\/2")}
                vertical-align top
                box-sizing border-box
                display inline-block
                width 48%

            &{unquote(".lg\:w-1\/2")} + {unquote(".lg\:w-1\/2")}
                margin-left 4%

            &{unquote(".lg\:w-1\/3")}
                vertical-align top
                box-sizing border-box
                display inline-block
                width 32%

            &{unquote(".lg\:w-1\/3")} + {unquote(".lg\:w-1\/3")}
                margin-left 2%

            &{unquote(".lg\:w-1\/4")}
                vertical-align top
                box-sizing border-box
                display inline-block
                width 23.5%

            &{unquote(".lg\:w-1\/4")} + {unquote(".lg\:w-1\/4")}
                margin-left 2%

    @media screen and (min-width: 1025px)
        .inline-form.step-item
            .form-generator
                display flex
            .form-generator-field.field-group-wrapper
                display flex
                flex-grow 1

                .form-generator-field
                    flex-grow 1

            .button-wrapper
                align-self flex-end


    .back-button
        &.disabled
            pointer-events none
            opacity 0
            width 0
            height 0
            padding 0
            margin 0

    .label-text a
        color var(--black)

    .encart
        border-radius 10px

    .encart :first-child
        display flex

    .encart p
        margin-top 0 !important
        padding 10px

    .encart svg
        display block
        width 20%
        min-width 39px

    .flex-wrapper
        display flex
        justify-content space-between
        flex-wrap wrap
        width 100%

        & > *
            padding-left 10px
            padding-right 10px
            margin-left auto
            margin-right auto

        &.columns-2
            display grid
            grid-column-gap 2em
            grid-row-gap 2em
            grid-template-columns repeat(2, minmax(0, 1fr))

            & > *
                margin-left unset
                margin-right unset

    .form-controls-wrapper
        text-align center
        padding-top 2em

    .link-bar
        background-color #fff
        border 1px solid var(--color3)
        padding-left 16px
        padding-right 16px
        flex-direction row
        justify-content start
        cursor pointer
        border-radius 10px
        position relative
        font-size 14px

        & > div
            display flex
            align-items center
            min-height 64px

            // .left-zone
            .right-zone
                margin-left 10px

    .link-bar:hover
        background-color var(--color3)
        border-color var(--color3)
        color var(--color3Inverse)

    .link-card
        background-color #FFF
        padding 20px
        box-shadow rgba(0, 0, 0, 0.08) 4px 4px 6px 3px
        cursor pointer
        transition transform ease .2s

    .field-link-wrapper.link-card:hover
        transform scale(1.05)

    .field-radio-wrapper.has-visible-errors
        display block

        .base-radio-button label
            border-color var(--AkMajorCoral)

    &.auto-continue
    .auto-continue
        .form-generator-field.field-radio-wrapper label
        .form-generator-field.field-checklist-wrapper label
            justify-content flex-start

            &:after
                background-image url('../assets/images/right@2x.png')

            // breaks on ios
            // &:hover:not(.is-checked)
            //     &:after
            //         background-image url('../assets/images/right@2x.png')

         .form-generator
                .form-generator-field.field-radio-wrapper label[class^="aca-img-"]
                .form-generator-field.field-radio-wrapper label[class*=" aca-img-"]
                .form-generator-field.field-checklist-wrapper label[class^="aca-img-"]
                .form-generator-field.field-checklist-wrapper label[class*=" aca-img-"]
                    justify-content flex-start

                    &:after
                        background-image url('../assets/images/right-white@2x.png')

                    // breaks on ios
                    // &:hover:not(.is-checked)
                    //     &:after
                    //         background-image url('../assets/images/right@2x.png')

    .form-generator-field label[class^="aca-icon-"]
    .form-generator-field label[class*=" aca-icon-"]
        display flex
        flex-direction row
        align-items center

        &:before
            font-size 40px
            color var(--color9)
            margin-right 10px

        &.is-checked
            &:before
                color #fff

        @media (hover: hover)
            &:hover
                &:before
                    color #fff

    .form-generator-field .base-radio-switch label[class^="aca-icon-"]
    .form-generator-field .base-radio-switch label[class*=" aca-icon-"]
        &:before
            font-size 24px

    .form-generator .form-generator-field label[class^="aca-img-"]
    .form-generator .form-generator-field label[class*=" aca-img-"]
        color #FFF
        padding-top 80px
        padding-bottom 48px
        background-size cover
        background-repeat no-repeat
        background-position 50% 0%

        @media (hover: hover)
            &:hover
                background-color var(--color3)
                background-image none

    .form-generator .field-radio-wrapper
    .form-generator .field-checklist-wrapper
        .before
            font-weight bold
            font-size 16px
            line-height 1.5
            color $AkGreyscaleBlack
            margin-top 2em

    .aca-img-etudiant
        background-image linear-gradient(rgba(10, 10, 10, 0.5), rgba(10, 10, 10, 0.8)), url("https://www.cdn.acadomia.fr/landings/radio-option-etudiant.jpg")
    .aca-img-eleve
        background-image linear-gradient(rgba(10, 10, 10, 0.5), rgba(10, 10, 10, 0.8)), url("https://www.cdn.acadomia.fr/landings/radio-option-eleve.jpg")
    .aca-img-adulte
        background-image linear-gradient(rgba(10, 10, 10, 0.5), rgba(10, 10, 10, 0.8)), url("https://www.cdn.acadomia.fr/landings/radio-option-adulte.jpg")
    .aca-img-parent
        background-image linear-gradient(rgba(10, 10, 10, 0.5), rgba(10, 10, 10, 0.8)), url("https://www.cdn.acadomia.fr/landings/radio-option-parent.jpg")

    .form-generator {
      font-size: 12px;
    }
    /*has-errors */
    .form-generator .base-input:not(.has-value) .after{
      display: none;
    }
    .form-generator .base-input.focused .after{
      display: none;
    }
    .form-generator .base-radio:not(.base-radio-bar) .after-option{
      display: none;
    }
    .form-generator .base-radio.base-radio-bar label:not(.is-checked) .after-option{
      display: none;
    }
    .form-generator .base-multi-select:not(.has-value) .after{
      display: none;
    }
    .form-generator .base-multi-select.focused .after{
      display: none;
    }

    .form-generator
        & > * + *
            margin-top 2em

        .errors
            margin-top 1em
            font-size 1.1em
            color var(--AkMajorCoral)

        h1, h2, h3, h4, h5, h6
            margin-top 1em

        .color-line:after
            content ''
            width 95px
            height 8px
            margin-left -60px
            display block
            margin-top 10px
            background-color var(--color4)

        p
            font-size 16px
            line-height 1.5
            color $AkGreyscaleBlack
            margin-top 2em

        small
            font-size 1em
            line-height 1.67
            color #222222

        a
            text-decoration underline
            color inherit

        & > .form-generator-field
            &.field-wide
                margin-left 0
                margin-right 0

            &.field-semi-wide
                margin-right 0

        .field-progress-bar-wrapper
            display flex
            justify-content center
            padding-left 10%
            padding-right 10%

    .base-input label
        padding-bottom 5px

    .base-input
    .base-select
        font-size 16px

    .step-wide .form-generator
        max-width 1100px

        h1, p
            text-align center

    .steps-wrapper
        transition height ease .4s
        position relative
        z-index 1

    .step-item
        margin auto

    .form-generator
    .button-wrapper
    .cgu-wrapper
        max-width 960px
        margin-left auto
        margin-right auto

    .button-wrapper
        display flex
        justify-content space-between
        flex-wrap wrap

    .base-button
        svg
            margin-left 10px
            width 24px
            height 24px

    .errors
        padding-top 10px
        color var(--faded-red)

    .block-group
        & > * + *
            margin-top 1em

    .block-type-wysiwyg
        & > * + *
            margin-top 1em

        .color-line
            position relative

        .color-line:before
            content ''
            height 4px
            width 1em
            display inline-block
            background var(--color4)
            position absolute
            top 0.7em
            transform translateX(-130%)

        h1.color-line:before
        h2.color-line:before
        h3.color-line:before
            display none
        h1.color-line:after
        h2.color-line:after
        h3.color-line:after
            content: "";
            width: 95px;
            height: 8px;
            margin-left: -60px;
            display: block;
            margin-top: 10px;
            background-color: var(--color4);

        .base-button
            font-size 16px

        .base-button-outline
            background transparent

        h1
            font-size 32px
            font-weight 800
            line-height 1.31
            color var(--color2)
            position relative

        h2
            font-size 26px
            font-weight bold
            line-height 1.38
            color var(--color9)
            position relative

        h3
            font-size 18px
            font-weight bold
            line-height 1.44
            color var(--color2)

        h5
            font-size 1.5em
            line-height 1.44
            font-weight bold

        h6
            font-size 1.4em
            font-weight bold

        p
            line-height 1.5
            color var(--color9)

        strong
            font-weight bold

        li
            font-size 18px
            margin-bottom 0.5em
            list-style none

        @media screen and (max-width: 1024px)
            .color-line:before
                display block
                position static
                transform none
                margin-bottom 5px
                width 1.5em

    .cgu-wrapper
        margin auto
        margin-top 2em

        .cgu
            text-align justify
            font-weight 500
            font-size 10px
            line-height 16px

        .cgu p
            font-size inherit
            font-weight inherit
            line-height inherit

// lg
@media screen and (min-width: 601px)
    .form-screen
        .hidden-lg
            display none !important

        .field-radio-wrapper
        .field-checklist-wrapper
            &.columns-4
                .base-radio .options-wrapper
                .base-checklist
                    display grid
                    grid-column-gap 2em
                    grid-row-gap 2em
                    grid-template-columns repeat(4, 1fr)

                    & > *
                        margin unset

                    .before
                        grid-column-start 1
                        grid-column-end 5

            &.columns-3
                .base-radio .options-wrapper
                .base-checklist
                    display grid
                    grid-column-gap 2em
                    grid-row-gap 2em
                    grid-template-columns repeat(3, 1fr)

                    & > *
                        margin unset

                    .before
                        grid-column-start 1
                        grid-column-end 4

            &.columns-2
                .base-radio .options-wrapper
                .base-checklist
                    display grid
                    grid-column-gap 2em
                    grid-row-gap 2em
                    grid-template-columns repeat(2, 1fr)

                    & > *
                        margin unset

                    .before
                        grid-column-start 1
                        grid-column-end 3

            &.item-large-lg
                label
                    min-height 100px
                    display flex
                    flex-direction column
                    align-items flex-start

// xs
@media screen and (max-width: 600px)
    .form-screen 
        .field-radio-wrapper
        .field-checklist-wrapper
            &.columns-4-xs
                .base-radio .options-wrapper
                .base-checklist
                    display grid
                    grid-column-gap 2em
                    grid-row-gap 2em
                    grid-template-columns repeat(4, 1fr)

                    & > *
                        margin unset

                    .before
                        grid-column-start 1
                        grid-column-end 5

            &.columns-3-xs
                .base-radio .options-wrapper
                .base-checklist
                    display grid
                    grid-column-gap 2em
                    grid-row-gap 2em
                    grid-template-columns repeat(3, 1fr)

                    & > *
                        margin unset

                    .before
                        grid-column-start 1
                        grid-column-end 4

            &.columns-2-xs
                .base-radio .options-wrapper
                .base-checklist
                    display grid
                    grid-column-gap 2em
                    grid-row-gap 2em
                    grid-template-columns repeat(2, 1fr)

                    & > *
                        margin unset

                    .before
                        grid-column-start 1
                        grid-column-end 3

                        
        .hidden-xs
            display none !important

        .product-3-col
            background-color transparent
            display block
            padding-left 0
            padding-right 0

            & > *
                margin-left 0
                margin-right 0

        .form-generator
            & > .form-generator-field
                max-width none
                margin-left auto
                margin-right auto

                &.field-wide
                    max-width none

                &.field-semi-wide
                    margin-left auto
                    margin-right auto

            .field-radio-wrapper
            .field-checklist-wrapper
                &.item-large-xs
                    label
                        min-height 100px
                        display flex
                        flex-direction column
                        align-items flex-start

        .form-generator-field label[class^="aca-icon-"]
        .form-generator-field label[class*=" aca-icon-"]
            &:before
                display none

        .form-controls-wrapper
            margin-left 0
            margin-right 0

        .flex-wrapper
            &.columns-2
                display block

                & > *
                    margin-left auto
                    margin-right auto
                    margin-bottom 30px

        .button-wrapper
            flex-direction column-reverse

            & > *
                margin-bottom 20px

@media screen and (max-width: 575px)
    .form-screen
        padding-top 0

        h1
            font-size 32px
            line-height 1.36
        p
            font-size 16px
            line-height 1.38

        .form-generator > * + *
            margin-top 24px

        .progress
            .numbers
                display none

    .form-screen.has-mobile-sticky-footer
        .button-wrapper
            position fixed
            left 0
            right 0
            bottom 0
            background-color var(--cobalt-blue)
            padding-left 24px
            padding-right 24px
            padding-bottom 12px
            padding-top 12px
            margin 0
            z-index 1

            .progress
                .numbers
                    display block

            .numbers
                color #FFF

            .background-bar
                background var(--navy-blue)

            .foreground-bar
                background #FFF

            .base-button
                font-size 14px
                min-width 115px
                height 40px

                &.continue-button
                    min-width 40px

                    span
                        display none

                    svg
                        margin 0
                        width 24px

                &.disabled
                    color #8F8FB8
                    opacity 1
                    background-color var(--navy-blue)
</style>
