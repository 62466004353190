// functions duplicated across Landings/LandingsFormulaires projects
import Handlebars from '@/Handlebars.js'

const isString = value => typeof value === 'string' || value instanceof String
const isObject = (value) => {
    if (
        typeof value === 'object' &&
        !Array.isArray(value) &&
        value !== null
    ) {
        return true
    }
    return false
}

export function dataForProspectsNew(model) {
    // Todo use stringify function like for diplomes ?
    // In order to handle variants containing string, collections and objects.

    const subjectValues = getVariants(model, 'subject')
    let subject = model['subject'] || Object.values(subjectValues).reverse().find(el => !!el)
    subject = Array.isArray(subject) ? subject.map((s) => s?.name ? s.name : s).join(', ') : subject

    let autreMatiere
    if (model.autreMatiere) {
        autreMatiere = Array.isArray(model.autreMatiere) ? model.autreMatiere.map((s) => s?.name ? s.name : s).join(', ') : model.autreMatiere
    }

    if ((subject || '').includes('Autre matière') && autreMatiere) {
        subject = subject.replace('Autre matière', autreMatiere)
    }

    const levelValues = getVariants(model, 'level')
    let level = model['level'] || Object.values(levelValues).reverse().find(el => !!el)
    level = stringify(level)

    const serieValues = getVariants(model, 'serie')
    let serie = model['serie'] || Object.values(serieValues).reverse().find(el => !!el)
    serie = stringify(serie)

    const fundingValues = getVariants(model, 'financement')
    let fundings = model['financement'] || Object.values(fundingValues).filter((x) => x)
    fundings = Array.isArray(fundings) ? fundings.join(', ') : fundings

    model['goal-autre'] = model['goal-autre'] ? 'Objectif: ' + model['goal-autre'] : ''
    const goalValues = getVariants(model, 'goal')
    const goals = Object.values(goalValues).filter((x) => x)
    const objectif = goals.length ? sanitize(goals.join(', ')) : ''

    const formatValues = getVariants(model, 'format')
    let format = model['format'] || Object.values(formatValues).reverse().find(el => !!el)
    format = stringify(format)

    const message = model.message || ''

    const sousRacineId = model.gtm_gclid
        ? 199
        : model.gtm_msclkid
        ? 200
        : 197;

    const supportId = model.gtm_gclid
        ? 1183
        : model.gtm_msclkid
        ? 1184
        : 1188;

    let data = {
        'origine': model.origine,
        'nom': removeAccents(sanitize(model.lastname)).toUpperCase(),
        'prenom': sanitize(model.firstname),
        'email': (model.email || '').trim(),
        'telephone': formattedPhoneNumberWithoutIndicatif(model.phone),
        'indicatifTelephone': getIndicatif(model.phone),
        'codePostal': model.zipcode?.cpCode,
        'ville': model.zipcode?.communeLib,
        'newsletter': model.newsletters ? true : false,
        'refererUrl': (model.landingName || '').substring(0, 29),
        'gtmGclId': model.gtm_gclid,
        'gtmUaClientId': model.gtm_ua_client_id,
        'gtmMsclkId': model.gtm_msclkid,
        'formulaireCode': (model.formCode || model.formulaireCode || '').substring(0, 29),
        'typeProspect': (model.type || '').substring(0, 9),
        'racineId': model.racineId || 6,
        'sousRacineId': model.sousRacineId || sousRacineId,
        'supportId': model.supportId || supportId,
        'paysId': 5,
        'nomVoie': '', // google address only
        'typeVoie': '', // google address only
        'repetition': '', // google address only
        'numVoie': 0, // google address only
        'civilite': model.profileType,
        'objectif': objectif,
        'matiere': (subject || '').substring(0, 49),
        'serie': serie,
        'niveau': level,
        'docSouhaitee': format,
        'marqueId': 1,
        'gamme': model.typeForm,
        'financement': fundings || '',
        'agenceId': parseInt(model.agencyId),
        'typeDocumentEvtAcs': model.evtEmail || model.typeDocumentEvtAcs || 'EVT_ACA_I0',
        'message': message,
        'utmSource': model.utmSource,
        'utmMedium': model.utmMedium,
        'utmCampaign': model.utmCampaign,
        'utmContent': model.utmContent,
    }

    if (model.scoreModelName && model.scoreMessage) {
        data[model.scoreModelName] = model.scoreMessage
    }

    let objet = []
    if (data.message) { objet.push(`${data.message}`) }

    data.objet = objet.filter(Boolean).join("\n")

    if (model.studentFirstname) {
        data.prenomEleve = model.studentFirstname
    }

    if (model.objetTemplate) {
        data.objet = [
            data.objet,
            Handlebars.compile(model.objetTemplate)({ payload: data, model: model })
        ].filter(Boolean).join("\n")
    }

    return data
}

export function dataForCreateTask(model, mailingName, profileType) {
    let data = {
        ...model,
        'message': model.message || 'pas de message',
        'dossierFamilleId': profileType === 'family' ? parseInt(model.personId) : undefined,
        'personnelId': model.personnelId || 0,
        'enseignantId': profileType === 'teacher' ? parseInt(model.personId) : 0,
        'agenceId': model.agenceId || 0,
        'typeTacheId': model.typeId || 25,
    }

    // Todo use stringify function like for diplomes ?
    // In order to handle variants containing string, collections and objects.

    const subjectValues = getVariants(model, 'subject')
    const subjects = Object.values(subjectValues).filter((x) => x)
    data.subject = subjects.length ? sanitize(subjects.join(', ')) : ''

    const goalValues = getVariants(model, 'goal')
    const goals = Object.values(goalValues).filter((x) => x)
    data.goal = goals.length ? sanitize(goals.join(', ')) : ''

    const levelValues = getVariants(model, 'level')
    const levels = Object.values(levelValues).filter((x) => x)
    data.level = levels.length ? sanitize(levels.join(', ')) : ''

    if (model.descriptionMessage) {
        data.tacheDescription = Handlebars.compile(model.descriptionMessage)({ model: data, mailingName })
    } else {
        if (model.contactType === 'phone') {
            data.tacheDescription = `Demande de rappel suite à l'emailing ${mailingName} : de préférence le ${formattedDate(model.date)} ${model.horaire}`
        } else {
            data.tacheDescription = `La famille ${model.personLastName} souhaite être recontactée suite à l'emailing ${mailingName} avec le message : ${data.message}.`
        }
    }

    return data
}

export function dataForContact(model, profileType) {
    let data = {
        "agenceId": 0,
        'dosfamId': profileType === 'family' ? parseInt(model.personId) : 0,
        'enseignantId': profileType === 'teacher' ? parseInt(model.personId) : 0,
        "personnelId": 1,
        "typeContId": model.typeContId || 149,
        "moyenContId": model.moyenContId || 2,
    }

    if (model.descriptionMessage) {
        data.contactCommentaire = Handlebars.compile(model.descriptionMessage)({ model, payload: data })
    } else {
        data.contactCommentaire = model.tacheDescription
    }

    return data
}

export function dataForTeachersNew(model) {
    let zip
    let city

    if (model.address) { // google address
        zip = model.address?.city?.zip
        city = model.address?.city?.name
    } else if (model.zipcode) { // zipcode component
        zip = model.zipcode?.cpCode
        city = model.zipcode?.communeLib
    }

    const origine = model.gtm_gclid
        ? 'Adwords'
        : model.gtm_msclkid
        ? 'Bing'
        : 'Internet'

    const diplomeMusique = sanitize(model.diplomeMusique)

    model.diplomeMusique = null // Avoid diplomeMusique being included in diplomes as a variant.
    const diplomeValues = getVariants(model, 'diplome')
    let diplomes = Object.values(diplomeValues).filter((x) => x)
    diplomes = stringify(diplomes)

    const matieres = model['matieres']?.map((name) => {
        if (name === 'autres') { return false }
        return {
            primaire: model[`niveaux-${name}`]?.includes('primaire'),
            college: model[`niveaux-${name}`]?.includes('college'),
            lycee: model[`niveaux-${name}`]?.includes('lycee'),
            universite: model[`niveaux-${name}`]?.includes('universite'),
            intituleMatiere: name,
        }
    })

    const autresMatieres = model['autres-matieres-niveaux']?.map(({ loopItem, value }) => {
        return {
            primaire: value?.includes('primaire'),
            college: value?.includes('college'),
            lycee: value?.includes('lycee'),
            universite: value?.includes('universite'),
            intituleMatiere: loopItem,
        }
    })

    const allMatieres = uniqBy([].concat(matieres, autresMatieres).filter(Boolean), 'intituleMatiere')

    const instruments = model['instruments']?.map((name) => {
        if (name === 'autres') { return false }
        return {
            intituleInstrument: name,
        }
    })

    const autresInstruments = model['autres-instruments']?.map(({ name }) => {
        return {
            intituleInstrument: name,
        }
    })

    const allInstruments = uniqBy([].concat(instruments, autresInstruments).filter(Boolean), 'intituleInstrument')

    const data = {
        'origine': origine,
        'nom': removeAccents(sanitize(model.lastname)).toUpperCase(),
        'prenom': sanitize(model.firstname),
        'email': (model.email || '').trim(),
        'telephone': formattedPhoneNumberWithoutIndicatif(model.phone),
        'indicatifTelephone': getIndicatif(model.phone),
        'codePostal': zip,
        'ville': city,
        'gtmGclId': model.gtm_gclid,
        'gtmUaClientId': model.gtm_ua_client_id,
        'gtmMsclkId': model.gtm_msclkid,
        'refererUrl': (model.referer_url || '').substring(0, 29),
        'nomVoie': '', // google address only
        'typeVoie': '', // google address only
        'repetition': '', // google address only
        'numVoie': 0, // google address only
        'civilite': model.profileType,
        matieres: allMatieres,
        gamme: model.gamme,
        'agenceId': parseInt(model.agencyId),
        'utmSource': model.utmSource,
        'utmMedium': model.utmMedium,
        'utmCampaign': model.utmCampaign,
        'utmContent': model.utmContent,
        diplomeMusique: diplomeMusique,
        diplome: sanitize(diplomes).substring(0, 199),
        urlCV: model.urlCV,
        instruments: allInstruments,
        dateNaissance: model.naissance?.split('/').reverse().join('-'),
    }

    if (model.estBac3Valide === 'true' || model.estBac3Valide === true) {
        data.estBac3Valide = true
    } else if (model.estBac3Valide === 'false' || model.estBac3Valide === false) {
        data.estBac3Valide = false
    }

    if (model.profession === 'Retraité') {
        data.professionId = 1
        data.activiteEnsId = 1
    } else if (model.profession === 'Enseignant') {
        data.professionId = 1
        data.activiteEnsId = 2
    } else if (model.profession === 'Etudiant') {
        data.professionId = 2
        data.activiteEnsId = null
    } else if (model.profession === 'Salarié') {
        data.professionId = 3
        data.activiteEnsId = null
    } else if (model.profession === 'Autre') {
        data.professionId = null
        data.activiteEnsId = null
    }

    return data
}

export function dataForCreatePartner(model) {
    // let zip
    // let city
    let cpId
    let communeId
    let adrNumVoie
    let repetitionCode
    let typevoieCode
    let adrNomVoie

    if (model.address) { // google address
        // zip = model.address?.city?.zip
        // city = model.address?.city?.name
        adrNumVoie = model.address?.number
        repetitionCode = model.address?.repetitionCode
        typevoieCode = model.address?.routeTypeCode
        adrNomVoie = model.address?.routeName
        cpId = model.address?.city?.cpId
        communeId = model.address?.city?.communeId
    } else if (model.zipcode) { // zipcode component
        // zip = model.zipcode?.cpCode
        // city = model.zipcode?.communeLib
        cpId = model.zipcode?.cpId
        communeId = model.zipcode?.communeId
    }

    return {
        "partgrandcompteId": model.partgrandcompteId || 0,
        "partclubIdentifiant": model.partclubIdentifiant || '',
        "civiliteCodeFamille": model.profileType || '',
        "nomFamille": sanitize(model.lastname) || '',
        "prenomFamille": sanitize(model.firstname) || '',
        "emailFamille": model.email || '',
        "adrNumVoie": adrNumVoie || model.adrNumVoie || 0,
        "repetitionCode": repetitionCode || model.repetitionCode || '',
        "typevoieCode": typevoieCode || model.typevoieCode || '',
        "adrNomVoie": adrNomVoie || model.adrNomVoie || 0 || '',
        "cpId": cpId || 0,
        "communeId": communeId || 0,
        "telNumero": formattedPhoneNumber(model.phone) || '',
        "civiliteCodeEleve": model.profilTypeEleve || '',
        "nomEleve": sanitize(model.lastnameEleve) || '',
        "prenomEleve": sanitize(model.firstnameEleve) || '',
        "emailEleve": model.emailEleve || '',
        "dateNaissEleve": model.dateNaissEleve || '',
        "typeEleve": model.typeEleve || '',
        "anneeScolId": parseInt(model.anneeScolId || 0),
        "niveauId": parseInt(model.niveau?.value || 0),
        "serieId": model.serieId || 0,
    }
}

export function dataForWebCallback(model) {
    return {
        ...model,
        'Telephone': formattedPhoneNumber(model.phone),
        'Codepostal': model.zipcode?.cpCode,
    }
}

export function dataForEmail(model) {
    let zip
    let cityId
    let city

    if (model.address) { // google address
        zip = model.address?.city?.zip
        city = model.address?.city?.name
    } else if (model.zipcode) { // zipcode component
        zip = model.zipcode?.cpCode
        cityId = model.zipcode?.communeId
        city = model.zipcode?.communeLib
    }

    return {
        ...model,
        'token': new URLSearchParams(model.queryString).toString(),
        'cityId': cityId,
        'city': city,
        'zip': zip,
    }
}

export function getIndicatif(phone) {
    if (!phone) { return undefined }
    if (phone instanceof Object) {
        return phone.countryCallingCode
    }
    return undefined
}

export function formattedPhoneNumberWithoutIndicatif(phone) {
    if (!phone) { return undefined }
    if (phone instanceof Object) {
        return phone.number.replace('+' + phone.countryCallingCode, '').replace(/\D/g, "")
    }
    return phone
}

export function formattedPhoneNumber(phone) {
    if (!phone) { return undefined }
    if (phone instanceof Object) {
        if (phone.countryCode?.toLowerCase() === 'fr') {
            return phone.number.replace('+33', '0')
        } else {
            return phone.number
        }
    }
    return phone
}

export function isValidDate(d) {
  return d instanceof Date && !isNaN(d)
}

export function formattedDate(date) {
    if (!isValidDate((new Date(date)))) {
        return ''
    }
    return (new Date(date)).toLocaleDateString('fr-FR', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
}

export function getCookieValue(a) {
    const b = document.cookie.match('(^|;)\\s*' + a + '\\s*=\\s*([^;]+)')
    return b ? b.pop() : ''
}

export function sanitize(str) {
    return (str || '')
        .replaceAll('&', '')
        .replaceAll('>', '')
        .replaceAll('<', '')
        .replaceAll('|', '')
        .replaceAll('/', '')
        .replaceAll('@', '')
        .replaceAll(':', '')
        .replaceAll('%', '')
        .replace(/\s+/g, ' ')
        .trim()
}

export function removeAccents(str) {
  return (str || '').normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

export function getVariants(model, prefix) {
    const matches = []
    for (const [key, value] of Object.entries(model)) {
        if (key.startsWith(prefix)) {
            matches.push([key, value])
        }
    }

    matches.sort(function(a, b) {
      return a[0].length - b[0].length || a[0].localeCompare(b[0])
    })

    return Object.fromEntries(matches)
}

export function uniqBy(arr, predicate) {
    const cb = typeof predicate === 'function' ? predicate : (o) => o[predicate]

    return [
        ...arr
            .reduce((map, item) => {
                const key = item === null || item === undefined ? item : cb(item)

                map.has(key) || map.set(key, item)

                return map
            }, new Map())
            .values(),
    ]
}

export function stringify(elem, separator = ', ') {
    if (isString(elem)) {
        return elem
    } else if (Array.isArray(elem)) {
        return elem.map(stringify).join(separator)
    } else if(isObject(elem)) {
        return stringifyObject(elem)
    }
}

export function stringifyObject(obj) {
    return obj && obj.value ? obj.value : ''
}
